exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alert-js": () => import("./../../../src/pages/alert.js" /* webpackChunkName: "component---src-pages-alert-js" */),
  "component---src-pages-baljachwi-js": () => import("./../../../src/pages/baljachwi.js" /* webpackChunkName: "component---src-pages-baljachwi-js" */),
  "component---src-pages-bowling-score-generator-js": () => import("./../../../src/pages/bowling-score-generator.js" /* webpackChunkName: "component---src-pages-bowling-score-generator-js" */),
  "component---src-pages-centralmembers-js": () => import("./../../../src/pages/centralmembers.js" /* webpackChunkName: "component---src-pages-centralmembers-js" */),
  "component---src-pages-character-js": () => import("./../../../src/pages/character.js" /* webpackChunkName: "component---src-pages-character-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-emblem-js": () => import("./../../../src/pages/emblem.js" /* webpackChunkName: "component---src-pages-emblem-js" */),
  "component---src-pages-father-js": () => import("./../../../src/pages/father.js" /* webpackChunkName: "component---src-pages-father-js" */),
  "component---src-pages-gathering-js": () => import("./../../../src/pages/gathering.js" /* webpackChunkName: "component---src-pages-gathering-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-institute-js": () => import("./../../../src/pages/institute.js" /* webpackChunkName: "component---src-pages-institute-js" */),
  "component---src-pages-introduction-js": () => import("./../../../src/pages/introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */),
  "component---src-pages-jehyang-js": () => import("./../../../src/pages/jehyang.js" /* webpackChunkName: "component---src-pages-jehyang-js" */),
  "component---src-pages-jonghun-js": () => import("./../../../src/pages/jonghun.js" /* webpackChunkName: "component---src-pages-jonghun-js" */),
  "component---src-pages-midfather-js": () => import("./../../../src/pages/midfather.js" /* webpackChunkName: "component---src-pages-midfather-js" */),
  "component---src-pages-nameorder-js": () => import("./../../../src/pages/nameorder.js" /* webpackChunkName: "component---src-pages-nameorder-js" */),
  "component---src-pages-newspaper-js": () => import("./../../../src/pages/newspaper.js" /* webpackChunkName: "component---src-pages-newspaper-js" */),
  "component---src-pages-openingstory-js": () => import("./../../../src/pages/openingstory.js" /* webpackChunkName: "component---src-pages-openingstory-js" */),
  "component---src-pages-origin-js": () => import("./../../../src/pages/origin.js" /* webpackChunkName: "component---src-pages-origin-js" */),
  "component---src-pages-otherevents-js": () => import("./../../../src/pages/otherevents.js" /* webpackChunkName: "component---src-pages-otherevents-js" */),
  "component---src-pages-pajo-js": () => import("./../../../src/pages/pajo.js" /* webpackChunkName: "component---src-pages-pajo-js" */),
  "component---src-pages-provincemembers-js": () => import("./../../../src/pages/provincemembers.js" /* webpackChunkName: "component---src-pages-provincemembers-js" */),
  "component---src-pages-regulations-js": () => import("./../../../src/pages/regulations.js" /* webpackChunkName: "component---src-pages-regulations-js" */),
  "component---src-pages-samseonghyeol-js": () => import("./../../../src/pages/samseonghyeol.js" /* webpackChunkName: "component---src-pages-samseonghyeol-js" */),
  "component---src-pages-scholarship-js": () => import("./../../../src/pages/scholarship.js" /* webpackChunkName: "component---src-pages-scholarship-js" */),
  "component---src-pages-scholarshipmembers-js": () => import("./../../../src/pages/scholarshipmembers.js" /* webpackChunkName: "component---src-pages-scholarshipmembers-js" */),
  "component---src-pages-wangwijeon-js": () => import("./../../../src/pages/wangwijeon.js" /* webpackChunkName: "component---src-pages-wangwijeon-js" */),
  "component---src-pages-yeonwon-js": () => import("./../../../src/pages/yeonwon.js" /* webpackChunkName: "component---src-pages-yeonwon-js" */),
  "component---src-templates-newspaper-page-js": () => import("./../../../src/templates/newspaper-page.js" /* webpackChunkName: "component---src-templates-newspaper-page-js" */),
  "component---src-templates-standard-post-js": () => import("./../../../src/templates/standard-post.js" /* webpackChunkName: "component---src-templates-standard-post-js" */)
}

